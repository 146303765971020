import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import Paginate from './paginate';
import {Spinner} from 'react-bootstrap'

class Contracts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      title: 'Yeouido Testnet Contracts',
      preps:[],
      activePage:0

    }
  }

  componentDidMount() {
    this.setState({ isLoading: true,title:'Yeouido Testnet Contracts' });
    let currentPage = this.props.match.params.currentPage ==='undefined' ? '0' : this.props.match.params.currentPage
    document.title = 'Yeouido Testnet Contracts';
    this.setState({isLoading:false,activePage:currentPage})
  }

static getDerivedStateFromProps(nextProps, prevState){

if(typeof(nextProps.match.params.currentPage!=='undefined')) {
 if(nextProps.match.params.currentPage!==prevState.currentPage){
   return { activePage: nextProps.match.params.currentPage, pageNumber: nextProps.match.params.currentPage};
}
else return null;
}
}


async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.currentPage!==this.props.match.params.currentPage){

}
}


render() {
    const { isLoading,activePage } = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }

return (
  <div className="Contracts container">
      <div className="row mb-5 mt-4">
        <div className="col-sm-12">

        <div className="card border-0 shadow-sm">
          <div className="card-body">
            <div className="row p-2">
              <div className="col-md-12">

                  <h3 className="float-left"><FontAwesomeIcon icon={faBox} /> Contracts</h3>

                    </div>

            </div>
            <Paginate forcedPage={activePage} />
      </div>
      </div>
            </div>

</div>


</div>
);
}
}

export default Contracts;
