import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Spinner,Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
const Posts = ({ currentPage, posts, loading }) => {

  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }

  return posts.map((line, index) => {


    const { address, contractName, balance, txCount, status, verifiedDate } = line //destructuring

     return (
       <tr key={index}>
       <td colSpan="3" className="col-special text-truncate">
       <Link to={`/address/${address}`}>
       {typeof(address) === 'undefined' || address === null ? '' : address.slice(0,15)+'...'}
       </Link>
       </td>
       <td colSpan="4" className="d-none d-lg-table-cell col-special text-truncate">{typeof(contractName) === 'undefiend' || contractName === null ? '-' : contractName.slice(0,25)}</td>
       <td colSpan="2" className="d-none d-lg-table-cell"><NumberFormat decimalScale='2' value={balance} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></td>
       <td colSpan="2"><NumberFormat decimalScale='0' value={txCount} displayType={'text'} thousandSeparator={true} prefix={''}/></td>
       <td colSpan="2" className="col-special">
       <Badge className='p-1 badge-width text-white' variant={status === '1' ? 'success' : 'danger'}>
       {status === '1' ? 'GOOD' : 'BAD'}
       </Badge></td>
       <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate">{verifiedDate === '-' ? '-' : <Moment >{verifiedDate}</Moment>}</td>
       </tr>
     )
   })
};

export default withRouter(Posts);
