import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';

const COPY_IMPORT_TEXT = 'Open Qrcode';

const QRCodes = ({ name }) => {
  const [text] = useState(COPY_IMPORT_TEXT);


  function Qrshow() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <OverlayTrigger placement="right"
          overlay={<Tooltip id={`copy ${name}`}>{text}</Tooltip>}
        >
          <div onClick={handleShow} className="small ml-3 text-color pointer">
            <FontAwesomeIcon className="small" icon={faQrcode} />
          </div>
        </OverlayTrigger>

        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header className="text-color bgLight" closeButton>
            <Modal.Title>QrCode</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center text-color bgLight p-5">
          <QRCode value={name} /><br /><br />
          <span className="text-break">{name}</span>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <Qrshow />

  );
};

export default ({ name }) => (
  <>
    <QRCodes name={name} />
  </>
);
