import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import {Spinner, Table, Badge, Tabs,Tab, Tooltip,OverlayTrigger} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactJson from 'react-json-view'


Number.prototype.noExponents= function(){
  var data= String(this).split(/[eE]/);
  if(data.length === 1) return data[0];

  var  z= '', sign= this<0? '-':'',
  str= data[0].replace('.', ''),
  mag= Number(data[1])+ 1;

  if(mag<0){
      z= sign + '0.';
      while(mag++) z += '0';
      return z + str.replace(/^-/,'');
  }
  mag -= str.length;
  while(mag--) z += '0';
  return str + z;
}

class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      preps:{},
      block_hash:0,
      hash:'',
      icxusd:0,
      value:0,
      tx:[{txHash:'1234'}],
      json:{data:'No Data.'},
      block: [],
      block_from:[],
      block_to:[],
      block_value:[],
      key: 1
    }
    this.scrollDiv = React.createRef();
  }
  async componentDidMount() {
    console.log(this.scrollDiv)

    switch (this.props.location.hash) {
      case ('#details'):
      this.setState({ isLoading: true, key:2, hash:'#votes' })
      this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
      break;
      case ('#raw'):
      this.setState({ isLoading: true, key:3, hash:'#raw' })
      this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
      break;
      case ('#transactions'):
      this.setState({ isLoading: true, key:1, hash:'#transactions' })
      this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
      break;
      default:
      this.setState({ isLoading: true, key:1})

    }


    // this.setState({ isLoading: true });
    let block_hash;
    if (this.props.match.params.blockHash.length > 8 && this.props.match.params.blockHash.length < 66) {
      this.props.history.push('/404');
      block_hash = this.props.match.params.blockHash < 0 ? 0 : this.props.match.params.blockHash;
    }  else if (this.props.match.params.blockHash.length >66) {
      this.props.history.push('/404')
      block_hash = this.props.match.params.blockHash < 0 ? 0 : this.props.match.params.blockHash;
    }  else {
      block_hash = this.props.match.params.blockHash < 0 ? 0 : this.props.match.params.blockHash;
    }
    document.title = 'Yeouido Block: '+block_hash;


    await (axios.get(`/data/thes`))
    .then(res => {
      this.setState({ preps:res.data });
    })

    if (block_hash.length === 66) {
      this.getBlockState(block_hash);
    } else {
      this.getBlockHeight(block_hash);
    }

  }

  static getDerivedStateFromProps(nextProps, prevState){

   if(nextProps.match.params.blockHash!==prevState.blockHash){
     return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
  } else if (nextProps.location.hash!==prevState.hash) {

    return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
  }
  else return null;
}

async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.blockHash!==this.props.match.params.blockHash){
    // this.setState({key:'1'})
    this.props.location.hash === '#details' ? this.setState({ key:2, hash:'#details' }) : this.props.location.hash === '#raw' ? this.setState({ key:3, hash:'#raw' }) : this.setState({ key:1 });
    document.title = 'Yeouido Block: '+this.props.match.params.blockHash;
    if (this.props.match.params.blockHash.length === 66) {
      this.getBlockState(this.props.match.params.blockHash);
    } else {
      this.getBlockHeight(this.props.match.params.blockHash);
        }
  }
  if(prevProps.location.hash!==this.props.location.hash){
    this.props.location.hash === '#details' ? this.setState({ key:2, hash:'#details' }) : this.props.location.hash === '#raw' ? this.setState({ key:3, hash:'#raw' }) : this.setState({ key:1 });
  }
}

  async getBlockState(block_hash) {
    // console.log("Block hash: " + block_hash);
    let currBlockObj;
    let price;
    let firstCall = await axios.get('./../data/data.json');
    let call = '{"jsonrpc":"2.0","id":1577502038080,"method":"icx_getBlockByHash","params":{"hash":"'+block_hash+'"}}'
    let secondCall =  axios.post('https://bicon.net.solidwallet.io/api/v3',call)
    await axios.all([firstCall, secondCall]).then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]

      price = responseOne.data.icxusd
      currBlockObj = responseTwo.data.result

    }))
    // console.log(currBlockObj);

    let tx = [];
     let value = 0;
     let raw = currBlockObj;
     currBlockObj.confirmed_transaction_list.forEach((i) => {
       tx.push(i);
    if (i.value != null) {
      value += (parseInt(i.value.slice(2,60),16) / 1000000000000000000)
    }
  })



    this.setState({
      isLoading: false,
      tx: tx,
      icxusd:price,
      json: raw,
      next_block: (currBlockObj.height + 1),
      previous_block: (currBlockObj.height - 1),
      block_hash: currBlockObj.block_hash,
      value: value,
      block_ts: (currBlockObj.time_stamp / 1000000),
      block_txs: parseInt(currBlockObj.confirmed_transaction_list.slice().length, 10),
      block: currBlockObj
    })
    // this.getBlockLeader(currBlockObj.peerId);

  }

  async getBlockHeight(block_hash) {
    block_hash = '0x'+parseInt(block_hash).toString(16);

    let price;
    let currBlockObj;
    let firstCall = await axios.get('./../data/data.json');
    let call = '{"jsonrpc":"2.0","id":1577495919111,"method":"icx_getBlockByHeight","params":{"height":"'+block_hash+'"}}'
    let secondCall = await axios.post('https://bicon.net.solidwallet.io/api/v3',call)

    await axios.all([firstCall, secondCall]).then(axios.spread((...responses) => {

      const responseOne = responses[0]
      const responseTwo = responses[1]

      price = responseOne.data.icxusd
      currBlockObj = responseTwo.data.result
  }))
    let tx = [];

     let value = 0;
     currBlockObj.confirmed_transaction_list.forEach((i) => {
    if (i.value != null) {
      value += (parseInt(i.value.slice(2,60),16) / 1000000000000000000)
    }
  })
    currBlockObj.confirmed_transaction_list.forEach((i) => { tx.push(i) }
)


    // Set the Component state
    this.setState({
      isLoading: false,
      tx: tx,
      icxusd:price,
      json: currBlockObj,
      next_block: (currBlockObj.height + 1),
      previous_block: (currBlockObj.height - 1),
      block_hash: currBlockObj.block_hash,
      value: value,
      block_ts: (currBlockObj.time_stamp / 1000000),
      block_txs: parseInt(currBlockObj.confirmed_transaction_list.slice().length, 10),
      block: currBlockObj
    })
    // this.getBlockLeader(currBlockObj.peerId);

  }

  // async getBlockLeader(peerId) {
  //   const {CallBuilder} = IconBuilder;
  //   const tokenAddress = 'cx0000000000000000000000000000000000000000'; // token address
  //   const call = new CallBuilder().to(tokenAddress).method("getPRep").params({address: peerId}).build();
  //   // console.log(call)
  //   const result = await iconService.call(call).execute();
  //   let name = result.name.toLowerCase().replace(/[&\\#, +()$~%.'":*?<>\-{}]/g, '');
  //   this.setState({block_leader: name})
  // }


  renderTxhead() {
    const {tx} = this.state;

  if (tx.length === 0) return 'No Transactions here!'

    return (
    <div className="table">
    <Table className="text-color" size="md" hover>
        <thead>
          <tr>
            <th className="border-top-0 text-center">Hash</th>
            <th className="border-top-0 text-center">Type</th>
            <th colSpan="3" className="border-top-0 text-center d-none d-lg-table-cell">Transaction</th>
            <th className="border-top-0 d-none d-lg-table-cell text-center"></th>
          </tr>
        </thead>
        <tbody>
          {this.renderTxs()}
        </tbody>
      </Table>
    </div>
)}
  renderTxs() {
    const {tx,preps} = this.state;
    return tx.map((tx, index) => {
       let { txHash, tx_hash, dataType, from, to, value } = tx //destructuring
       if (typeof(txHash) === 'undefined') {txHash = '0x'+tx_hash}
       return (
         <tr key={index}>
             <td className="col-special text-center text-truncate align-middle"><div className="media border-0 p-0 justify-content-left">
             <Link to={`/tx/${txHash}`}>{txHash.slice(2,14)+'...'}</Link>
             </div></td>
             <td className="col-special text-center"><div className="media border-0 p-0 justify-content-center">
             <Badge className='p-1 badge-width' variant={(typeof(dataType) === 'undefined' || (dataType=== 'message')) ? 'primary' : ((dataType=== 'call') ? 'secondary' : 'dark')}>
             {(typeof(dataType) === 'undefined' || (dataType=== 'message')) ? 'TRANSFER' : ((dataType=== 'call') ? 'CONTRACT' : 'SYSTEM')}
             </Badge>
             </div></td>
           <td colSpan="3" className="col-special d-none d-lg-table-cell text-left"><div className="media border-0 p-0 justify-content-center">
           {typeof(from) === 'undefined' ? '' : (
           <div><Jazzicon className={typeof(from) === 'undefined' ? 'd-none' : 'd-inline-block'}  diameter={20} seed={typeof(from) === 'undefined' ? 0 : jsNumberForAddress(from)} />
         </div>)}&nbsp;
           <Link to={typeof(from) === 'undefined' ? '/' : '/address/'+from }>
           {typeof(from) === 'undefined' ? '' : (typeof(preps[from]) === 'undefined') ? from.slice(0,10)+'...' : preps[from] }
           </Link>
           <div><FontAwesomeIcon className={typeof(from) === 'undefined' ? 'd-none' : 'd-inline-block mr-3 ml-3'} icon={faLongArrowAltRight} /></div>
           {typeof(to) === 'undefined' ? '' : (
             <div><Jazzicon diameter={20} seed={typeof(to) === 'undefined' ? 0 : jsNumberForAddress(to)} /></div>
           )}
           &nbsp;
           <Link to={typeof(to) === 'undefined' ? '/' : '/address/'+to }>
           {typeof(to) === 'undefined' ? '' : (typeof(preps[to]) === 'undefined') ? to.slice(0,10)+'...' : preps[to] }
           </Link>
           </div></td>
           <td className="col-special d-none d-lg-table-cell text-center"><div className="media border-0 p-0 justify-content-right">
           <Badge className='float-left p-1 badge-width media-bg'>
             <NumberFormat value={typeof(value) === 'undefined' ? 0 : (parseInt(value.slice(2,60),16) / 1000000000000000000).noExponents()} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
           </Badge>
           </div></td>
         </tr>
       )
     })
  }

  handleClick = (e) => {
    // const {block_hash} = this.state
    // console.log('handleClick', e);
    this.setState({key:e})
    // console.log('test')
    switch (e) {
      case '1':
      default:
        return this.props.history.push("#transactions")
      case '2':
        return this.props.history.push("#details")
      case '3':
        return this.props.history.push("#raw")

    }
  }

  extraData() {
    const { isLoading } = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return(
      <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.handleClick(k)} transition={false}>
      <Tab mountOnEnter={true} eventKey="1" title="Transactions">
      <div className="container">
      {this.renderTxhead()}
      </div>
      </Tab>
      <Tab mountOnEnter={true} eventKey="2" title="More Details">
      <div className="container">
      <div className="row">
        <div className="col-md-6">
        <div className="table">
          <table className="table text-color text-left">
            <tbody>
            <tr>
            <th className="border-top-0">Merkle tree:</th>
          <td className="border-top-0">
          <code className="text-color">{this.state.block.merkle_tree_root_hash}</code>
          </td>
          </tr>
          <tr>
            <th>Version:</th>
          <td>
            {this.state.block.version}
          </td>
          </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-6">
        <div className="table">
          <table className="table text-color text-left">
            <tbody>

      <tr>
        <th className="border-top-0">Parent block:</th>
      <td className="border-top-0">
        <Link to={`../block/${'0x'+this.state.block.prev_block_hash}`}>{'0x'+this.state.block.prev_block_hash}</Link>
      </td>
      </tr>
      <tr>
        <th>Signature:</th>
      <td><textarea disabled spellCheck='false' className="form-control rounded-0" value={this.state.block.signature}></textarea></td>
      </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
      </div>
      </Tab>
      <Tab mountOnEnter={true} eventKey="3" title="Raw Data">
      <div className="container">
      <div className="overflow-auto p-2 media-bg text-left">
      <ReactJson src={(typeof(this.state.json)) === 'undefined' ? {data:'No Data'} : (this.state.json)} />
      </div>
      </div>
      </Tab>
      </Tabs>


    )

  }



  topTable(value){
    const { isLoading, preps, icxusd } = this.state;
    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return(

      <div className="row">
        <div className="col-md-6 overflow-auto ">
      <div className="table">
        <table className="table text-color text-left">
          <tbody>
            <tr>
              <th className="border-top-0">Block height:</th>
              <td className="border-top-0">
              <NumberFormat value={this.state.block.height} displayType={'text'} thousandSeparator={true} prefix={''}/>&nbsp;&nbsp;
              <div className="d-inline-block">
              <Link className={this.state.block.height === 0 ? 'd-none' : 'd-inline'} to={`../block/${this.state.previous_block}`}>&larr;Prev</Link>&nbsp;

              <Link to={`../block/${this.state.next_block}`}>Next&rarr;</Link>&nbsp;
              </div>
              </td>
            </tr>


            <tr>
              <th>Time:</th>
              <td>
                <Moment format="YYYY/MM/DD HH:mm:ss" unix="unix">{this.state.block_ts}</Moment>
              </td>
            </tr>
            <tr>
              <th>Block producer:</th>
              <td>
                <Link to={`../address/${this.state.block.peer_id}`}>{typeof(preps[this.state.block.peer_id]) !== 'undefined' ? preps[this.state.block.peer_id] : this.state.block.peer_id}</Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



              <div className="col-md-6">
            <div className="table">
              <table className="table text-color text-left">
                <tbody>
                  <tr>
                    <th className="border-top-0">Transactions:</th>
                    <td className="border-top-0"><NumberFormat value={this.state.block_txs} displayType={'text'} thousandSeparator={true} prefix={''}/> transaction(s)</td>
                  </tr>
                  <tr>
                    <th>Value:</th>
                    <td>
                    <NumberFormat decimalScale='20' value={(value.noExponents())} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-left"><NumberFormat decimalScale='4' value={icxusd} displayType={'text'} thousandSeparator={true} prefix={' @ $'} suffix={'/ICX'}/></Tooltip>}>
      							<NumberFormat decimalScale='2' value={((value*icxusd)) < 0.005 ? 0 : value*icxusd} displayType={'text'} thousandSeparator={true} prefix={' ($'} suffix={')'}/>
      							</OverlayTrigger>

                    </td>
                  </tr>
                  <tr>
                  <th>Block hash:</th>
                <td>
                <code className="text-color">{'0x'+this.state.block.block_hash}</code>
                </td>
                </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
    )
  }

  render() {
    const { isLoading,value } = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return (
<div className="Block mt-4">
      <div className="container">
      <div className="card text-color border-0 shadow-sm">
     	 <div className="card-body pb-0 rounded">
     		 <div className="container">
     			 <div className="row">
              <div className="grey-border p-2 mb-3 col-md-12 text-left">
                  <h4 className="display-5 mt-2"> <Jazzicon diameter={20} seed={this.state.block.height} />
<NumberFormat value={this.state.block.height} displayType={'text'} thousandSeparator={true} prefix={' Block '}/></h4>
                    <h6 className="d-none d-md-inline text-secondary">{'0x'+this.state.block.block_hash}</h6>

              </div>
            </div>

{this.topTable(value)}
      </div>
    </div>
  </div>

  <div ref={this.scrollDiv} className="card mt-3 text-color border-0 shadow-sm">
{this.extraData()}
        </div>
        </div>
        </div>
  );
  }
}
export default withRouter(Block);
