import React from 'react';
import { Link } from 'react-router-dom';
import {Spinner,Tooltip,OverlayTrigger} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import {Badge} from 'react-bootstrap'
import { FlagIcon } from './../Clipboard/flag';

const Posts = ({ currentPage, posts, loading, leader,allposts, price }) => {

  if (loading) {
    return <tr className="p-5"><td><Spinner animation="border" variant="info" /></td></tr>;
  }

    const addDefaultSrc = (ev) => {
    ev.target.src = './../logos/noprofile.png'
  }

  let iRep = 0;
  let topDelegated = 0;
  let totalDelegated = 0


  allposts.forEach(function(element, index) {
  let delegated = (parseInt((element.delegated.slice(0,50)),16)/1000000000000000000);
  let new_rep = (parseInt((element.irep.slice(0,50)),16)/1000000000000000000);
  totalDelegated += delegated
  if (index <= 21) {
  topDelegated += delegated;
  iRep += new_rep * delegated;
  }
  })

  let newiRep = (iRep / topDelegated);

  return posts.map((prep, index) => {



     const { name, address, delegated, validatedBlocks, irep, grade, totalBlocks, country } = prep //destructuring
     let img = './../logos/'+address+'.png'

     return (
       <tr key={index}>
       <td colSpan="1"><span className="d-flex align-items-center">{(index+1 + ((currentPage*40)-40))}</span></td>
         <td colSpan="5" className="text-truncate"><div className="d-flex d-flex align-items-center">
         <span className="logoIcon float-left"><img src={img} onError={addDefaultSrc} alt='' /></span>
         <Link to={`/address/${address}`}>{name}</Link></div>
         </td>
         <td colSpan="3">
         <Badge className={`p-1 m-auto badge-width text-color prime-badge btn ${(leader === address) ? 'btn-outline-danger' : (grade === '0x0') ? 'btn-outline-info' : (grade === '0x2') ? 'btn-outline-primary' : 'btn-outline-secondary'}`}>{(leader === address) ? 'LEADER' : (grade === '0x0') ? 'MAIN' : (grade === '0x1') ? 'SUB' : 'PREP'}</Badge>
         </td>
         <td colSpan="3" className= "d-none d-lg-table-cell"><div className="d-flex d-flex align-items-center">
         <FlagIcon code={country !== '' || typeof(country) !== 'undefined' ? country : 'WW'} size={20} />&nbsp;{country !== '' || typeof(country) !== 'undefined' ? country : 'WWW'}
         </div></td>
         <td colSpan="3" className="d-none d-md-table-cell">
         <NumberFormat value={(validatedBlocks/totalBlocks)*100} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={''} suffix={'%'}/>
         </td>
         <td colSpan="3" className= "d-none d-lg-table-cell">
         <NumberFormat decimalScale='2' value={(parseInt((irep.slice(2,40)),16)/1000000000000000000).toLocaleString(undefined, {maximumFractionDigits: 0})} displayType={'text'} thousandSeparator={true} prefix={''}/>
         </td>
         <td colSpan="3" className= "d-none d-lg-table-cell">
         <NumberFormat value={(parseInt((delegated.slice(2,40)),16)/1000000000000000000).toLocaleString(undefined, {maximumFractionDigits: 0})} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={''} suffix={''}/>
         </td>
         <td colSpan="4" className="d-none d-md-table-cell">
         <div className="d-flex flex-column">
         <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-left"><NumberFormat value={((index+1 + ((currentPage*50)-50)) <= 22) ? (((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) + (newiRep * (1 / 2))) / 30)*price : ((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) / 30)*price } decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={'$'}/></Tooltip>}>
         <NumberFormat value={((index+1 + ((currentPage*50)-50)) <= 22) ? (((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) + (newiRep * (1 / 2))) / 30) : ((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) / 30) } decimalScale='2' displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
         </OverlayTrigger>
         </div>
         </td>
       </tr>
     )
   })
};

export default Posts;
