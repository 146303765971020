import React from 'react';
import {Pagination as Pages} from 'react-bootstrap'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  let pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (pageNumbers.length > 2 ) {

   if (currentPage < 2) {
    // let number = pageNumbers.length
    pageNumbers = pageNumbers.slice(0,3)
    // pageNumbers.push(number)

  } else if (currentPage === pageNumbers.length || currentPage === pageNumbers.length-1){

    pageNumbers = pageNumbers.slice(pageNumbers.length-3, pageNumbers.length)

  } else {

    pageNumbers = pageNumbers.slice(currentPage-2,currentPage+1)

}

}

  return (
    <Pages>
      <Pages.Prev disabled={(currentPage === pageNumbers[0]) ? true : false } onClick={() => paginate(currentPage-1)}>Prev</Pages.Prev>
      {/*(currentPage === 1 || currentPage === 2 || currentPage === 3 || currentPage === 4 ) ? '' : (<Pages.First onClick={() => paginate(1)}>1</Pages.First>)*/}
      {/*(currentPage === 1 || currentPage === 2 || currentPage === 3 || currentPage === 4 ) ? '' : (      <Pages.Ellipsis disabled="true" />)*/}

      {pageNumbers.map((number, index) => (

        <Pages.Item key={index} active={currentPage === number ? true : false } onClick={() => paginate(number)}>{number}</Pages.Item>

        ))}
        {/*((currentPage === pageNumbers[(pageNumbers.length-1)]) || currentPage === pageNumbers[(pageNumbers.length-2)] || currentPage === pageNumbers[(pageNumbers.length-3)] || currentPage === pageNumbers[(pageNumbers.length-4)] ) ? '' : (   <Pages.Last onClick={() => paginate(pageNumbers.length)} />)*/}
        {/*((currentPage === pageNumbers[(pageNumbers.length-1)]) || currentPage === pageNumbers[(pageNumbers.length-2)] || currentPage === pageNumbers[(pageNumbers.length-3)] || currentPage === pageNumbers[(pageNumbers.length-4)] ) ? '' : (   <Pages.Ellipsis disabled={true} />)*/}
        <Pages.Next disabled={(currentPage === pageNumbers[(pageNumbers.length-1)]) ? true : false } onClick={() => paginate(currentPage+1)}>Next</Pages.Next>


    </Pages>
  );
};

export default Pagination;
