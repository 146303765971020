import React, { useState, useEffect } from 'react';
import Posts from './Posts';
import Pagination from './../Pagination';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import {Spinner} from 'react-bootstrap'

const Voters = ({account, leader}) => {
  const [posts, setPosts] = useState({listSize:0,data:[{address:'hx1000000000000000000000000000000000000000'}]});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);

  useEffect(() => {

    setCurrentPage(1);

  }, [account]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      var link = 'https://bicon.tracker.solidwallet.io/v3/iiss/delegate/list?page='+currentPage+'&count='+postsPerPage+'&prep='+account
      // console.log(link)
      const res = await axios.get(link);
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage,postsPerPage,account]);

  // useEffect(() => {
  //   // setLoading(false);
  //   const fetchPosts = async () => {
  //     // setLoading(true);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     var link = 'https://bicon.tracker.solidwallet.io/v3/iiss/delegate/list?page='+currentPage+'&count='+postsPerPage+'&prep='+account
  //     // console.log(link)
  //     const res = await axios.get(link);
  //     setPosts(res.data);
  //     // setLoading(false);
  //   };
  //   fetchPosts();
  //
  // }, [currentPage])


  const currentPosts = posts.data

  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (loading) {
    return <div className="row h-50 m-5"><div className="col-sm-12 h-100 p-5 m-5"><Spinner animation="border" variant="info" /></div></div>;
  }
  return (


    <div className="container">
    <div className="row pb-3" >
    A total of
    <NumberFormat className="font-weight-bold" decimalScale='2' value={posts.listSize} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;'/>
     addresses are currently voting for&nbsp;<span className="font-weight-bold">{(posts.listSize === 0) ? 'unknown?' : (typeof(leader[posts.data[0].prep]) === 'undefined') ? posts.data[0].prep.slice(0,14)+'...' : leader[posts.data[0].prep] }
    </span></div>

    <div className="row" >

    <Posts currentPage={currentPage} posts={currentPosts} loading={loading} leader={leader} />
      </div>
      <div className="pl-2 pb-0 mb-0 pt-2">
      <div className={posts.listSize < 40 ? 'd-none' : 'd-block' }>
      <Pagination
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        totalPosts={posts.listSize}
        paginate={paginate}
      />
      </div>
      </div>
      </div>
  );
};

export default Voters;
