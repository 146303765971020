import React from 'react';
import { Link } from 'react-router-dom';
import {Spinner,Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Posts = ({ currentPage, posts, loading, leaders }) => {
  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }
  return posts.map((line, index) => {

    const { createDate, txHash, amount, fromAddr, toAddr, height, state } = line //destructuring
    let from = fromAddr
    let to = toAddr
    let hash = txHash
    let timestamp = createDate
    let status = state
     return (
       <tr key={index}>
       <td colSpan="2" className="col-special text-truncate">
       <span className="d-inline-block d-md-none">TX Hash:&nbsp;</span>
       {((txHash === 'genesistx 2' || txHash === 'genesistx 1' || txHash === 'genesistx 3' || txHash === 'genesistx 4' || txHash === 'genesistx 5' || txHash === 'genesistx 0')) ? txHash : (<Link to={`/tx/${hash}`}>{hash.slice(2,10)+'...'}</Link>)}
       </td>
       <td colSpan="2" className="d-none d-lg-table-cell col-special text-truncate "><Moment fromNow >{timestamp}</Moment></td>
       <td colSpan="2"className="d-none d-lg-table-cell"><Link to={`/block/${height}`}><NumberFormat decimalScale='0' value={height} displayType={'text'} thousandSeparator={true} prefix={''}/></Link></td>
       <td colSpan="5"><div className="media border-0 p-0 mx-auto">
       {(typeof(fromAddr) === 'undefined' || fromAddr === 'genesistx 0') ? ' ' : (
       <div className="d-none d-md-block"><Jazzicon diameter={20} seed={(fromAddr === 'genesistx' || typeof(fromAddr)) === 'undefined' || fromAddr === null  ? 0 : jsNumberForAddress(fromAddr)} /></div>
     )}
       &nbsp;
       {(fromAddr ==='genesistx 0') ? 'genesistx 0' : (from === '' || fromAddr === null) ? 'System' :
        (<Link to={`/address/${fromAddr}`}>
        {typeof(fromAddr) === 'undefined' ? '' : (typeof(leaders[fromAddr]) === 'undefined') ? fromAddr.slice(0,12)+'...' : leaders[fromAddr] }
        </Link> )}
       <FontAwesomeIcon className="mt-1 mb-1 mr-3 ml-3" icon={faLongArrowAltRight} />
       <div className="d-none d-md-block"><Jazzicon diameter={20} seed={typeof(to) === 'undefined' || typeof(to) === 'undefined' || to === null  ? 0 : jsNumberForAddress(to)} /></div>
       &nbsp;
       {(to === '' || to === null) ? 'System' : (
       <Link to={`/address/${to}`}>
       {typeof(to) === 'undefined' ? '' : (typeof(leaders[to]) === 'undefined') ? to.slice(0,12)+'...' : leaders[to] }
       </Link>)}
       </div>
       </td>
       <td colSpan="2">
       <Badge className='p-1 badge-width media-bg text-color'>
       <NumberFormat decimalScale='2' value={amount} displayType={'text'} thousandSeparator={true} prefix={'ICX '}/>
       </Badge>
       </td>
       <td colSpan="2" className="d-none d-lg-table-cell col-special text-truncate">
       <Badge className="badge-width" variant={status === 1 ? 'success' : 'danger'}>
       {status === 1 ? 'SUCCESS' : 'FAILED'}
       </Badge>
     </td>
       </tr>
     )
   })
};

export default Posts;
