import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Button, Navbar, Dropdown, Nav,NavItem, Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import axios from 'axios';
// import LineChart from './../Dashboard/highchart';


class Home extends Component {
_isMounted = false;
intervalID;

constructor(props) {
  super(props);
  this.state = {
    isLoading: true,
    tx: [],
    block: [],
    block_value: [],
    new_transactions: [],
    block_times: [],
    links:[],
    countries:[],
    votes:[],
    productivity:[],
    names:[],
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
}

async componentDidMount() {
  this.setState({isLoading:true})
  document.title = 'Yeouido Testnet Explorer';
  this._isMounted = true;
      this.getData();
  this.intervalID = setInterval(this.getData.bind(this), 2500);
  this.setState({isLoading:false})

}


componentWillUnmount() {
  clearTimeout(this.intervalID);
  this._isMounted = false;
}

async getData() {
  await axios.get('https://bicon.tracker.solidwallet.io/v0/main/mainInfo')
  .then(res => {
    // console.log(res.data)
    if (this._isMounted) {
    this.setState({block: res.data.tmainBlock,tx:res.data.tmainTx});
    }
  })
  .catch(errors=>{})

}

renderTransactions() {
  const TxData = this.state.tx.slice(0,10)
  return TxData.map((tx, index) => {
     const { createDate, txHash } = tx //destructuring
     return (
       <div key={index} className="media">
         <div className="media-body text-color text-truncate">
           <div>
         <h6>Tx&nbsp;
           <Link to={`/tx/${txHash}`}>
              {typeof(txHash) === 'undefined' ? '' : txHash.slice(0,24)}...
            </Link>
            <span className="float-right d-none font-weight-light d-lg-block"><Moment fromNow>{createDate}</Moment></span>
          </h6>

         </div>
     </div>
     </div>
     )
   })
}

renderBlocks() {
  const Blocks = this.state.block.slice(0,10);

  return Blocks.map((tx, index) => {
     const { blockHeight, createDate, txCount} = tx //destructuring
     return (


       <div key={index} className="media">
         <div className="media-body">
         <h6>Block
<Link to={`/block/${blockHeight}`}> <NumberFormat value={blockHeight} displayType={'text'} thousandSeparator={true}/></Link>
  <span className="float-right d-none font-weight-light d-lg-block"><Moment fromNow>{createDate}</Moment></span>
       </h6>
     <div>

 </div>

     </div>
     </div>
     )
   })
}


  handleChange(event) {
    // console.log(event.target.name)
    let key = event.target.name

    this.setState(
      {[key] : event.target.value});
  }

async handleSubmit(event) {
  event.preventDefault();
    // console.log(this.state);
    let value = this.state.value.replace(/[^\w\s]/gi, '').toLowerCase();


    // const checkBlock = async (block_hash) => {
    //   let currBlockObj = 'test';
    //   currBlockObj = (await iconService.getBlockByHash(block_hash).execute());
    //
    //   // console.log(currBlockObj)
    //   return currBlockObj;
    // }
    let value2 = parseFloat(value)
    // console.log(value2)
    if (value.length === 0) {
      console.log('fun')
    } else if (value.length <= 8 && isNaN(value2) !== true) {
      this.props.history.push('/block/' + value);
    } else if (value.slice(0,2) === 'cx') {
      this.props.history.push('/address/' + value);
    } else if (value.slice(0,2) === 'hx') {
      this.props.history.push('/address/' + value);
    } else if (value.slice(0,2) === '0x') {
      // await checkBlock(value)
      // .then(res => {
      //   const rsult = res.data.data.height;
      //   alert(res)
      //   if (typeof (rsult) == 'undefined') {
      //       this.props.history.push('/block/' + value);
      //   } else {
          this.props.history.push('/tx/' + value);
        // }

    // )
      // .catch(err => {
      //   alert(err)


      // an error occurred, call the done function and pass the err message
      // this.props.history.push('/block/' + value);
    //   }
    // );
      // let checkblock = ;
      // console.log(checkblock)

    //   if (typeof (checkblock) === null) {
    //     this.props.history.push('/block/' + value);
    //
    // } else {
    //   this.props.history.push('/tx/' + value);
    // }
    //
    } else {
            this.props.history.push('/404');
            // console.log(value.slice(0,2))
    }
    // console.log(newLocation)
    // this.props.history.push(newLocation);
    this.setState({value: ''}) // <= here

  }


renderChartHead() {
  return (

    <div className="row">
      <div className="col-md-12">
        <h4>Daily Transactions</h4>
      </div>
    </div>
)}


render() {

const {isLoading} = this.state

if (isLoading) {
return <p>Loading ...</p>;
}
return (
      <div className="Home container mt-4">
      <div className="row">
      <div className="col-12">

<div className="card bg-icon border-0 shadow-sm">
<div className="card-body p-4">
<Form onSubmit={this.handleSubmit}>
<div className="d-none d-sm-flex align-items-baseline">
<h6 className="text-white"><FontAwesomeIcon icon={faNetworkWired} /> Yeouido Testnet Explorer </h6>
</div>
<div className="input-group border-0 text-color">
<FormControl type="text" name="value" className="border-0 rounded-right-0 text-color list-unstyled py-3 mb-0" value={this.state.value} onChange={this.handleChange} autoComplete="off" spellCheck="false" placeholder="Search for Address, Block #, Tx Hash" />
<div className="input-group-append">
<Button variant="dark" className="rounded-left-0" type="submit"onClick={this.handleSubmit}>
<span className="d-sm-inline-block">Search</span>
</Button>
</div>
</div>
</Form>
</div>
</div>
</div>
      </div>

      <div className="row mt-3">
      <div className="col-sm-6 pr-3 pl-3">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
            <div className="row p-1">
              <div className="col-md-12">
                <h4 className="float-left">Latest Blocks</h4>
              </div>
            </div>
            {this.renderBlocks()}
            <div className="card-footer border-0">
              <Link to={`../blocks`} className="btn btn-outline-info btn-xs btn-block">View all blocks</Link>
            </div>
            </div>
          </div>
          </div>

          <div className="col-sm-6 pr-3 pl-3">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="row p-1">
                <div className="col-md-12">
                  <h4 className="float-left">Latest Transactions</h4>
                </div>
              </div>
              {this.renderTransactions()}
              <div className="card-footer border-0">
                <Link to={`../transactions`} className="btn btn-outline-info btn-xs btn-block">View all transactions</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

);
}
}

export default Home;
