import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Spinner,Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
const Posts = ({ currentPage, posts, loading }) => {

  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }

  return posts.map((line, index) => {


    const { createDate, txCount, hash, amount, fee, peerId, height } = line //destructuring

     return (
       <tr key={index}>
         <td colSpan="2"><Link to={`/block/${height}`}><NumberFormat decimalScale='0' value={height} displayType={'text'} thousandSeparator={true} prefix={''}/></Link></td>
         <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate"><Moment fromNow >{createDate}</Moment></td>
       <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate"><Link to={`/block/${hash}`}>{hash}</Link></td>
       <td colSpan="1" className="col-special text-truncate">{txCount}</td>
       <td colSpan="2" className="d-none d-lg-table-cell col-special text-truncate">
       <div className="d-flex align-items-center">
     <Jazzicon diameter={18} seed={typeof(peerId) === 'undefined' || peerId === 0 ? 0 : jsNumberForAddress(peerId)} />&nbsp;<Link to={`/address/${peerId}`}> {peerId.slice(0,8)+'...'}</Link>
     </div></td>
<td colSpan="2" className="col-special">
<Badge className='p-1 badge-width media-bg text-color'>
<NumberFormat decimalScale='2' value={amount} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
</Badge></td>
     <td colSpan="2" className="col-special d-none d-lg-table-cell"><NumberFormat decimalScale='5' value={fee/1000000000000000000} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></td>
       </tr>
     )
   })
};

export default withRouter(Posts);
