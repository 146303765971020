import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Spinner} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
const Posts = ({ currentPage, posts, loading }) => {

  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }

  return posts.map((line, index) => {


    const { contractAddr, name, symbol, marketCap, volume, changeVal, transferCount } = line //destructuring

     return (
       <tr key={index}>
       <td colSpan="3" className="col-special text-truncate">
       <Link to={`/token/${contractAddr}`}>
       {typeof(contractAddr) === 'undefined' || contractAddr === null ? '' : contractAddr.slice(0,15)+'...'}
       </Link>
       </td>
       <td colSpan="4" className="d-none d-lg-table-cell col-special text-truncate">{typeof(name) === 'undefiend' || name === null ? '-' : name.slice(0,25)}{' ('}{typeof(symbol) === 'undefiend' || symbol === null ? '-' : (symbol)}{')'}</td>
       <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate">
       <NumberFormat decimalScale='2' value={transferCount === null ? 0 : transferCount} displayType={'text'} thousandSeparator={true}/>
       </td>
       <td colSpan="2" className="d-none d-lg-table-cell"><NumberFormat decimalScale='2' value={marketCap === null ? 0 : marketCap} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></td>
       <td colSpan="2"><NumberFormat decimalScale='0' value={volume === null ? 0 : volume} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></td>
       <td colSpan="2" className="col-special">
       <NumberFormat decimalScale='2' value={changeVal === null ? 0 : changeVal} displayType={'text'} thousandSeparator={true} suffix={'%'}/>
       </td>

       </tr>
     )
   })
};

export default withRouter(Posts);
