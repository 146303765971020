import { faClipboard } from '@fortawesome/free-regular-svg-icons/faClipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copy from 'copy-text-to-clipboard';
import React, { useCallback, useMemo, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const COPY_IMPORT_TEXT = 'Copy ';
const COPIED_IMPORT_TEXT = 'Copied!';

const ClipBoard = ({ name, type }) => {
  const [text, setText] = useState(COPY_IMPORT_TEXT+type);
  let tpe = type;
  const textToCopy = useMemo(
    () => name,
    [name],
  );



  const handleCopy = useCallback(() => {
    copy(textToCopy);
    setText(COPIED_IMPORT_TEXT);
    setTimeout(() => setText(COPY_IMPORT_TEXT+ tpe), 2000);
  }, [textToCopy,tpe]);



  return (
    <OverlayTrigger placement="right"
      overlay={<Tooltip id={`copy ${name}`}>{text}</Tooltip>}
    >
      <div onClick={handleCopy} className="small ml-3 text-color pointer">
        <FontAwesomeIcon className="small" icon={faClipboard} />
      </div>
    </OverlayTrigger>
  );
};

// export default ClipBoard;


export default ({ name, type }) => (
  <>
    <ClipBoard name={name} type={type} />
  </>
);
