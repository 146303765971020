import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Spinner,Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
const Posts = ({ currentPage, posts, loading }) => {

  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }

  return posts.map((line, index) => {


    const { address, icxUsd, balance, txCount, status, percentage,nodeType } = line //destructuring

     return (
       <tr key={index}>
       <td colSpan="3" className="col-special text-truncate">
       <Link to={`/address/${address}`}>
       {typeof(address) === 'undefined' || address === null ? '' : address.slice(0,15)+'...'}
       </Link>
       </td>
       <td colSpan="3" className="d-none d-lg-table-cell"><NumberFormat decimalScale='2' value={balance} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></td>
       <td colSpan="3" className="d-none d-lg-table-cell"><NumberFormat decimalScale='2' value={icxUsd} displayType={'text'} thousandSeparator={true} suffix={' USD'}/></td>
       <td colSpan="2" className="d-none d-lg-table-cell"><NumberFormat decimalScale='2' value={percentage} displayType={'text'} thousandSeparator={true} suffix={'%'}/></td>
       <td colSpan="2"><NumberFormat decimalScale='0' value={txCount} displayType={'text'} thousandSeparator={true} prefix={''}/></td>
       <td colSpan="2" className="d-none d-lg-table-cell col-special text-truncate">{nodeType === '-' ? '-' : nodeType}</td>
       </tr>
     )
   })
};

export default withRouter(Posts);
