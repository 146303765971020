import React, { Component } from 'react';
import {Row, Col, Form, FormControl, Button} from 'react-bootstrap'
import {wait} from './../Clipboard/wait'
import axios from 'axios'

const API = 'https://api.iconwat.ch/faucet/';
const MSG = {
  msg1 : 'This is not a valid ICON address',
  msg2 : 'On its way!'
}

class Faucet extends Component {

        constructor(props) {
          super(props);
          this.state = {
            isLoading: true,
            value:'',
            text:'',
            type:''
          }
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleChange = this.handleChange.bind(this);
        }

componentDidMount() {
  this.setState({ isLoading: true });
  document.title = 'Yeouido Testnet Faucet';

  this.setState({ isLoading: false })
}


handleChange(event) {
  let key = event.target.name

  this.setState(
    {[key] : event.target.value});
}

async handleSubmit(event) {
event.preventDefault();

  let value = this.state.value.replace(/[^\w\s]/gi, '').toLowerCase();

  if (value.length === 42 && (value.slice(0,2) === 'hx' || value.slice(0,2) === 'cx') ) {
  let obj = {
    network: 'yeouido',
    address: value
  }

  await axios.post(API,obj).then(res => {

    typeof(res.data.Error) === 'undefined'
    ? this.setState({text: MSG.msg2,type:'text-success'})
    : this.setState({text: res.data.Error,type:'text-danger'})
  })
  this.setState({value: ''}) // <= here

} else {
  setTimeout(() => this.setState({text: MSG.msg1,type:'text-danger'}), 5000)
}


}

render() {
  const { isLoading, text, type} = this.state;
  return isLoading
    ? wait()
    : (
          <div id="Faucet" className="faucetbg">

          <div className="pb-5 m-sm-0">
          <h1 className="text-white font-weight-bold m-0">
            Testnet Faucet
          </h1>
          <Row className="m-0 p-0 d-flex justify-content-center">
          <div className="container">
          <div className="card text-color border-0 shadow-sm mt-3">
          <div className="card-body rounded pt-2 pr-2 pl-2 pb-5">
          <h5 className="display-6 mt-5">Looking for Testnet ICX?
          </h5>
          <h6>Fill out your Yeouido testnet address to receive 100 ICX
          </h6>
          <div className="card-body m-4">
          <div id="faucetForm">
          <Form onSubmit={this.handleSubmit} >
          <div className="input-group border-0 text-white v-50">
          <FormControl type="text" name="value" className="border-0 code list-unstyled py-3 mb-0" value={this.state.value} onChange={this.handleChange} autoComplete="off" spellCheck="false" placeholder="Testnet wallet address" />
          </div>
          <Button className="bg-icon border-0 pt-2 pb-2 pl-4 pr-4 mt-3 rounded" type="submit"onClick={this.handleSubmit}>
          <code className="d-sm-inline-block font-weight-bold text-white">Request ICX</code>
          </Button>
          <div className={`mt-1 ${type}`}>&nbsp;<br/>
          {text === '' ? '' : <span>{text}</span>}
          &nbsp;</div>
          </Form>
          </div>

          </div>


          </div>
          </div>
          </div>

          </Row>
          </div>
          </div>
);
}
}

export default Faucet;
